@import url('https://fonts.googleapis.com/css?family=Quicksand:400,700'); // feel free to change this font type at any momment.

$primary: #344154; /* MAIN COLOR */
$secondary: #484d5b; /* SECONDARY COLOR */
$gray: #666; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$footerLinks: #d5d5d5;

@import url('https://fonts.googleapis.com/css?family=Gloria+Hallelujah|Rock+Salt|Special+Elite');

h1,h2,h3 {
	font-family: 'Special Elite', sans-serif;

	@media (max-width: 767px) {
		font-size: 1.5em;
	}	

	@media (max-width: 420px) {
		font-size: 1.25em;
	}	
}

h1 {
	font-size: 4em;
	@media (max-width: 990px) {
		font-size: 2.5em;
	}

	@media (max-width: 767px) {
		font-size: 1.5em;
	}	
	@media (max-width: 767px) {
		font-size: 1em;
		line-height: 1.4em;
		letter-spacing: 1px;
	}		
}

p {
	font-family: 'Gloria Hallelujah',sans-serif;
	font-size: 1.5em;
	letter-spacing: 1px;
	line-height: 2em;

	@media (max-width: 990px) {
		line-height: 1.5em;
	}

	@media (max-width: 420px) {
		font-size: 1em;
	}	


}

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #0065dd;
  -webkit-text-fill-color: #0065dd;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}


body {
	color: $primary;
}

.row {
	padding-top: 4em;
	padding-bottom: 4em;

	@media (max-width: 767px) {
		padding-top: 2em;
		padding-bottom: 2em;
	}		
}

nav {
	z-index: 10;
	&.navbar {
		min-height: 100px;

		@media (max-width: 990px) {
			min-height: auto;
		}
	}
}
.navbar .navbar-nav {
> li > a {
			text-align: center;
			margin-top: 22px;
			display: flex;
			align-items: center;
			color: black;
			transition: 1s all ease;
			margin-right: 0.5em;


			@media (max-width: 767px) {
				margin-top: 0;
			    display: inline-block;
			    height: 25px;
			}

		    &:hover {
		    	background: $primary;
		    	color:$wht;
		    	@media (max-width: 1024px) {
			    	background: transparent;
	    			color: $blk;
				  	outline: 0;
		    	}
		    }

			&:focus, &:active {
			  background: transparent;
			  color: $blk;
			  outline: 0;
			}
		}
}
.navbar-toggle {
    margin: 18px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}

// here start mixins ---- this is a style for buttons you can add it to your a tag like this (@include btn, look at the .btn-default style), these values can be changed anytime you need to.
@mixin btn{
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: 0px;
	background: $primary;
	color: $wht;
	padding: 0.5em 1em;
	font-size: 1.25em;
	font-weight: bold;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 1px;
	margin: 2em 0em;

	&:hover {
		background: lighten($primary,10%);
		color: $wht;
		box-shadow: 0px 0px 3px lighten($blk,20%);
		text-decoration: none;

	}
}

.btn-default {
	@include btn;
}
// mixins end here


// sections start
.section-a {
	padding: 60px;
	margin-top: 100px; // this marging  will depend on the height of the nav, (if you have a bigger or smaller nav feel free to change it)  'if you need a transparent nav and see the img change margin to padding'

}
// sections end


.modal-dialog {
	max-width: 500px;
	width: 100%;
	text-align: center;
	margin: 6em auto;

	.close {display: none;}

	.modal-content {
		color: $primary;

		h2 {
			text-align:center;
		}
	}

	a.btn, a.btn-default, button {
		@include btn
		margin: 0;
		display: block;
	}
}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

.modal-open {
  overflow: auto;
  padding-right: 0px !important;
}


footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;
	font-size: 15px;
	
	a {
		color: $footerLinks;
		white-space: nowrap;
		font-size: 15px;

		&:hover {
			color: lighten($footerLinks, 10%);
			outline: 0 !important;
 			text-decoration: none;
		}
		
		&:focus {
			color: $footerLinks;
			outline: 0 !important;
 			text-decoration: none;
		}		
	}

	p {
		font-size: 15px;

		@media (max-width: 990px) {
			font-size: 13px;
		}
	}	
}



.logo {
	max-width: 250px;
	padding: 0.5em;
}


@media(max-width: 768px) {
	.logo {
		max-width: 175px;
	}

    .navbar-header a {
        float: left;
    }
}




video { 
	width: 100%;
	height: auto;
	display: block; /* THIS NEEDS TO STAY BLOCK OR WILL HAVE MAGICAL PADDING */
	object-fit: cover;
}



.videoBox {
	position: relative;
	min-width: 100%;
	min-height: 100%;
	overflow: hidden;
	margin-top: -10em;
	padding: 0;


	@media (max-width: 1200px) {
		// margin-top: -4em;
		margin-top: 0em;
	}

	@media (max-width: 767px) {

	}

	& + * {
		position: relative;
		margin-top: -1px;
	}
}


.banner {
	background: linear-gradient(
		rgba(255,255,255,0.6),
		rgba(255,255,255,0.6)
		), url(../img/video.jpg) no-repeat top;
	background-size: cover;
	padding: 15em 0em;
	
	@media (max-width: 767px) {
		padding: 5em 2em;
	}

}


.video-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(55,55,55,0.6);
	min-height: 100%;
    transition: all 1s ease;
	-webkit-transform: translate3d(0,0,0); /* MAY NEED TO KEEP TRANSITION SMOOTH */
 

    &:hover {
    	background:  darken(rgba($primary,0.75),30%);
    }

    h1 {
    	position: absolute;
    	left: 50%;
    	top: 50%;
    	transform: translateX(-50%) translateY(-50%);
    	text-transform: uppercase;
    	text-align: center;
    	color: white;
    	text-shadow: 0px 0px 15px black;
    	transition: all 1s ease;
    }
}

.table {
	display: table;
}

.table-cell {
	display: table-cell;
}

hr {
	max-width: 300px;
	border: 2px solid $primary;
	border-radius: 5px;
}

.primaryBg {
	background: $primary;
	color: $wht;


	hr {
		max-width: 300px;
		border: 2px solid $wht;
		border-radius: 5px;
	}	
}

.img-circle {
	max-width: 60%;
	border: 8px solid $primary;
}